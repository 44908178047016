import "./App.css";
import React, { useState, useCallback, useEffect } from "react";
// import ScrollToTop from "./components/scrollToTop";
// import { Routes, Route } from "react-router-dom";
// import Navbar from "./components/navbar";
// import Footer from "./components/footer";
// import DownloadDrawer from "./components/downloadDrawer";
import useLanguage from "./hooks/useLanguage";
// import Home from "./pages/Home";
// import AboutUs from "./pages/AboutUs";
// import FAQ from "./pages/FAQ";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import PageNotFound from "./pages/PageNotFound";
import ComingSoonPage from "./pages/ComingSoon";


function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(true); // Loader state
  const { language, toggleLanguage } = useLanguage();

  const openDrawer = useCallback(() => setIsDrawerOpen(true), []);
  const closeDrawer = useCallback(() => setIsDrawerOpen(false), []);


  return (
    // <>
    //   <Navbar
    //     onDownloadClick={openDrawer}
    //     onLanguageToggle={toggleLanguage}
    //     language={language}
    //   />
    //   <ScrollToTop />

    //   <Routes>
    //     <Route index path="/" element={<Home language={language} />} />
    //     <Route path="/aboutUs" element={<AboutUs language={language} />} />
    //     <Route path="/plans" element={<ComingSoonPage language={language} />} />
    //     <Route path="/FAQ" element={<FAQ language={language} />} />
    //     <Route
    //       path="/privacy-policy"
    //       element={<PrivacyPolicy language={language} />}
    //     />
    //     <Route path="/*" element={<PageNotFound language={language} />} />
    //   </Routes>

    //   {isDrawerOpen && (
    //     <DownloadDrawer
    //       isOpen={isDrawerOpen}
    //       onClose={closeDrawer}
    //       language={language}
    //     />
    //   )}

    //   <Footer language={language} />
    // </>
    <>
    <ComingSoonPage />
    </>
  );
}

export default React.memo(App);
