import React from "react";
// import { Mail, Github, Twitter, Linkedin } from "lucide-react";

const ComingSoonPage = ({ language }) => {
  return (
    <div className="relative min-h-screen bg-black text-white overflow-hidden">
      {/* Animated Background */}
      {/* <div className="absolute inset-0 opacity-20">
        {[...Array(50)].map((_, i) => (
          <div
            key={i}
            className="absolute rounded-full"
            style={{
              width: Math.random() * 300 + 50 + "px",
              height: Math.random() * 300 + 50 + "px",
              left: Math.random() * 100 + "%",
              top: Math.random() * 100 + "%",
              background:
                "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)",
              transform: "translate(-50%, -50%)",
              animation: `float ${Math.random() * 10 + 20}s linear infinite`,
            }}
          />
        ))}
      </div> */}

      {/* Main Content */}
      <div className="relative min-h-screen flex flex-col items-center justify-center p-4">
        <div className="max-w-4xl w-full text-center ">
          {/* <h1 className="text-5xl md:text-7xl font-bold mb-8 animate-fade-in">
            {language === "ar" ? "قريباً" : "Coming Soon"}
          </h1> */}

          <p className="text-xl md:text-2xl text-gray-400 mb-12 animate-slide-up">
            {language === "ar"
              ? "نحن بصدد إنشاء شيء استثنائي. \n ترقبوا ثورة في خدمات النقل."
              : "We're crafting something extraordinary.\n Stay tuned for the revolution in ride-hailing."}
          </p>
        </div>
      </div>

      {/* Add animation keyframes */}
      <style jsx>{`
        @keyframes float {
          0%,
          100% {
            transform: translate(-50%, -50%) scale(1);
          }
          50% {
            transform: translate(-50%, -50%) scale(1.2);
          }
        }
        .animate-fade-in {
          animation: fadeIn 1s ease-out;
        }
        .animate-fade-in-delay {
          animation: fadeIn 1s ease-out 0.5s backwards;
        }
        .animate-fade-in-delay-2 {
          animation: fadeIn 1s ease-out 1s backwards;
        }
        .animate-slide-up {
          animation: slideUp 1s ease-out 0.25s backwards;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @keyframes slideUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default ComingSoonPage;
